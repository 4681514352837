import { createRouter, createWebHistory } from "vue-router";

import MainHome from "../views/MainHome.vue";
import eBizService from "../views/eBizService.vue";
import eBizSolution from "../views/eBizSolution.vue";
import Digital from "../views/Digital.vue";
import eBizAbout from "../views/eBizAbout.vue";
import eBizLocation from "../views/eBizLocation.vue";
import EpicService from "../views/EpicService.vue";



const routes = [
  {
    path: "/",
    name: "home",
    component: MainHome,
  },

  {
    path: "/e-biz",
    name: "e-biz",
    component: eBizService,
  },

  {
    path: "/e-solution",
    name: "e-solution",
    component: eBizSolution,
  },

  {
    path: "/e-digital",
    name: "e-digital",
    component: Digital,
  },

  {
    path: "/e-epicService",
    name: "e-epicService",
    component: EpicService,
  },

  {
    path: "/e-bizAbout",
    name: "e-bizAbout",
    component: eBizAbout,
  },

  {
    path: "/e-location",
    name: "e-location",
    component: eBizLocation,
  }

  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
