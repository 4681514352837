<template>
    <body class="busi-p">
        <header id="header" class="layout-header" :class="{'scroll-on':currentScroll}">
            <TopMenu />
        </header>
        <!-- sub banner -->
        <section class="container-fluid sub-banner ebiz-banner business-banner">
            <div class="sub-banner--title-wrap">
                <div class="sub-banner--sub-txt">Business Solution</div>
                <div class="sub-banner--txt">
                    <span class="line">복잡한 회원관리와 전산이 고민일 때</span>
                    <span class="line">똑똑한 네트워크 마케팅 비즈니스 플랫폼을 찾고 있다면?</span>
                </div>
            </div>
        </section>

        <section class="business-ezen">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10">
                        <p class="txt-desc">고민의 해답! 최적의 네트워크 마케팅 비즈니스 전용 플랫폼</p>
                        <h2 class="tit-main mt-4">Ezen Cloud</h2>
                        <p class="txt-sub mt-4 mb-5">MLM Soft Cloud Platform '이젠 클라우드'는 수 많은 마케팅 회사를 비즈니스 성공의 길로 이끌었습니다. <br>
                            앞으로도 여러분의 비즈니스 목표를 이룰 수 있도록 혁신적인 네트워크 마케팅 비즈니스 솔루션을 제공하기 
                            위해 언제나 최선을 다합니다.
                        </p>
                        <a href="https://ezencloud.co.kr" class="tab-button mt-4 mt-lg-5" type="button">EZEN CLOUD 홈페이지 바로가기</a>
                    </div>
                    <div class="col-lg-6 business-ezen-img">
                        <img src="@/assets/img/img_business_01.png" alt="">
                    </div>
                </div>
            </div>
        </section>

        <section class="container business-allinone">
            <div class="row">
                <div class="col-lg-8">
                    <p class="txt-desc mb-4">#갓성비 #갓심비 #AtoZ 플랫폼</p>
                    <h2 class="tit-main">완벽한 비즈니스를 
                        위한 맞춤형 <br>
                        All-in-One 솔루션
                    </h2>
                </div>
                <div class="col-lg-8">
                    <div class="business-allinone-card">
                        <h3 class="business-allinone-tit">
                            비용부담은 dowm↓ <br>
                            보안은 더 강력하게 UP↑
                        </h3>
                        <p class="txt-sub">AWS Cloud 기반 <br>
                            합리적인 비용으로 서비스 제공 <br>
                            AWS DB로 안전성은 덤!
                        </p>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="business-allinone-card business-allinone-card02">
                        <h3 class="business-allinone-tit">
                            글로벌 확장? <br>
                            시스템 확장? <br>
                            No problem!
                        </h3>
                        <p class="txt-sub">시스템 구축, 확장부터
                            문제해결, 재해 복구 등 <br>
                            빠른 대응! 쉬운 확장! 서비스
                        </p>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="business-allinone-card business-allinone-card03">
                        <h3 class="business-allinone-tit">모바일도? 태블릿도! <br>
                            어떤 디바이스도 매끄럽게
                            볼수있는 반응형 UI/UX
                        </h3>
                        <p class="txt-sub">PC, 모바일, 태블릿 <br>
                            어떤 디바이스에서도 <br>
                            사용자 친화적인 반응형 UI/UX
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-gray ac-service">
            <div class="container">
                <h2 class="tit-main mb-4">EZEN CLOUD의<br class="business-m-br"> 주요 서비스</h2>
                <p class="txt-sub ac-service-space">지금 바로 쇼핑몰 홈페이지는 물론, 회원들을 위한 마이오피스 페이지부터 <br class="business-m-br">
                    관리자를 위한 ERP 페이지까지, 사업에 필요한 모든 것이 담긴 EZEN CLOUD(이젠클라우드)를 타사 설치형 프로그램 대비
                    가장 합리적인 가격으로 도입할 수 있습니다.
                </p>
                <div class="row p-r">
                    <div class="col-8 col-lg-4 ac-service-card-wrap">
                        <div class="ac-service-card">
                            <img src="@/assets/img/business-service01.svg" alt="">
                            <h3 class="ac-service-tit">똑똑한 <br>
                                매출관리 솔루션
                            </h3>
                            <p class="txt-sub">전사업무, 회원, 수당, 물류,
                                주문 관리 및 승급처리, 알림 등
                            </p>
                        </div>
                    </div>
                    <div class="col-8 col-lg-4 ac-service-card-wrap">
                        <div class="ac-service-card">
                            <img src="@/assets/img/business-service02.svg" alt="">
                            <h3 class="ac-service-tit">프리미엄 <br>
                                마이오피스
                            </h3>
                            <p class="txt-sub">비즈니스별, 디바이스별 <br>
                                최적의 마이오피스 플랫폼
                            </p>
                        </div>
                    </div>
                    <div class="col-8 col-lg-4 ac-service-card-wrap">
                        <div class="ac-service-card">
                            <img src="@/assets/img/business-service03.svg" alt="">
                            <h3 class="ac-service-tit">브랜드 <br>
                                홈페이지 제작
                            </h3>
                            <p class="txt-sub">브랜드 이미지와 스토리를
                                바탕으로 맞춤형 홈페이지 제작
                            </p>
                        </div>
                    </div>
                    <div class="col-8 col-lg-4 ac-service-card-wrap">
                        <div class="ac-service-card">
                            <img src="@/assets/img/business-service04.svg" alt="">
                            <h3 class="ac-service-tit">쇼핑몰 관리
                            </h3>
                            <p class="txt-sub">마케팅, 간편결제 시스템, <br>
                                상품 등록, 배송처리 등 <br>
                                관리 서비스
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container section-my">
            <p class="txt-desc mb-4 mb-lg-0">우리 비즈니스에는 어떤 플랫폼이 어울릴까?</p>
            <h2 class="tit-main business-pf-tit">비즈니스 목적에 따라 선택하는 맞춤형 플랫폼!</h2>
            <div class="row">
                <div class="col-lg-5 mb-5 mb-lg-0">
                    <div class="business-pf-card">
                        <img src="@/assets/img/business-pf01.png" alt="" class="business-pf-img">
                        <div class="business-pf-card-content">
                            <h3 class="tit-sub">후원방문판매형</h3>
                            <ul class="business-pf-ul">
                                <li>백오피스</li>
                                <li>쇼핑몰</li>
                                <li>맞춤형 홈페이지</li>
                                    <li>후원방문판매 전용 마이오피스</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 mb-5 mb-lg-0">
                    <div class="business-pf-card business-pf-card02">
                        <img src="@/assets/img/business-pf02.png" alt="" class="business-pf-img">
                        <div class="business-pf-card-content">
                            <h3 class="tit-sub">네트워크마케팅 판매형</h3>
                            <ul class="business-pf-ul">
                                <li>백오피스</li>
                                <li>쇼핑몰</li>
                                <li>홈페이지</li>
                                <li>네트워크마케팅 전용 마이오피스</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 mb-5 mb-lg-0">
                    <div class="business-pf-card business-pf-card03">
                        <img src="@/assets/img/business-pf03.png" alt="" class="business-pf-img">
                        <div class="business-pf-card-content">
                            <h3 class="tit-sub">방문판매형</h3>
                            <ul class="business-pf-ul">
                                <li>백오피스</li>
                                <li>쇼핑몰</li>
                                <li>홈페이지</li>
                                <li>방문판매 전용 마이오피스</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-blue">
            <div class="container">
                <h2 class="tit-mid c-white mb-5">EZEN CLOUD가 더 궁금해 졌다면 <br>
                    홈페이지 둘러보고 전문가 상담과 소개서도 받아보세요!
                </h2>
                <a href="https://ezencloud.co.kr" type="button" class="btn-line link-btn">EZEN CLOUD 홈페이지 바로가기</a>
            </div>
        </section>
        <Footer />
</body>
</template>

<script setup>
import {ref, onMounted} from 'vue'
// 상단 Navbar 컴포넌트
import TopMenu from '../components/common-element/TopMenu.vue'
// Footer
import Footer from '../components/common-element/Footer.vue'

// TopScroll
const currentScroll = ref("")

const topScrollEvent = () =>{
    if(window.scrollY > 100){
        currentScroll.value = "scroll-on"
    }else {
        currentScroll.value = ""
    }
}

onMounted(( )=>{
    window.addEventListener('scroll', topScrollEvent);
})
</script>

