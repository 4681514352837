<template>
    <section class="section ebiz-tel-section">
        <div class="container">
            <div class="row align-content-center">
                <div class="col-lg-10 tel-area-txt">
                    <p class="ebiz-tel-section-txt">디지털 비즈니스 성공을 위한<br class="mo-br"> 가장 빠른 방법, 작은 고민도 저스티드와 나누고 소통해보세요!</p>
                </div>
                <div class="col-lg-6 tel-area-btn">
                    <button onclick="self.location.href = 'tel:02-2051-4877'" type="button" class="btn-line btn-line--call"><span>02-2051-4877</span></button> 
                    <button onclick="self.location.href = 'mailto:support@justed.co.kr'" type="button" class="btn-line btn-line--email ml-5">support@justed.co.kr</button>
                </div>
            </div>
        </div>
    </section>
</template>