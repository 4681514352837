import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script';

import 'swiper/swiper-bundle.css';
import '@/assets/css/styles.css'
import '@/assets/css/styles.min.css'
import '@/assets/css/scss/styles.scss'

// Vue.use(LoadScript);
createApp(App).use(store).use(router).use(LoadScript).mount("#app");
