<template>
    <section class="container biz-area--wrap">
        <div class="row">
            <div class="col-lg-7 make-me-sticky">
                <div class="tit-line">
                    <span>사업영역</span>
                </div>
                <h2 class="tit-main mt-3 mb-4 mt-xl-5">Business Area</h2>
                <ul class="txt-sub d-none d-lg-block">
                    <li>e-Biz Service</li>
                    <li>Business Solution</li>
                    <li>Digital CX·UIUX</li>
                    <li>EPIC Service</li>
                </ul>
            </div>
            <div class="col-lg-9">
                <article class="biz-area-card">
                    <div class="row">
                        <div class="col-lg-7 top-view">
                            <h3 class="biz-area-card--tit">e-Biz Service</h3>
                            <p class="biz-area-card--txt">비즈니스에 대한 심층 분석과 시스템에
                                최적화된 서비스 운영까지
                                Total-Care 서비스를 제공합니다.
                            </p>
                            <a href="/e-biz" aria-label="바로가기" class="btn-go mt-5"></a>
                        </div>
                        <div class="col-lg-9 biz-area-card--img under-view">
                            <img src="@/assets/img/home-biz-1.png" alt="">
                        </div>
                    </div>
                </article>

                <article class="biz-area-card">
                    <div class="row">
                        <div class="col-lg-7 top-view">
                            <h3 class="biz-area-card--tit">Business Solution</h3>
                            <p class="biz-area-card--txt">합리적인 가격과 업계 특화 솔루션으로
                                견고하고 완벽한 비즈니스를 위한
                                맞춤형 All-in-One 서비스를 제공합니다.
                            </p>
                            <a href="/e-solution" aria-label="바로가기" class="btn-go mt-5"></a>
                        </div>
                        <div class="col-lg-9 biz-area-card--img under-view">
                            <img src="@/assets/img/home-biz-2.png" alt="">
                        </div>
                    </div>
                </article>

                <article class="biz-area-card">
                    <div class="row">
                        <div class="col-lg-7 top-view">
                            <h3 class="biz-area-card--tit">Digital CX·UIUX</h3>
                            <p class="biz-area-card--txt">고객 경험의 지속 성장과 최적의
                                채널 전략 실현을 위한
                                혁신적인 UIUX 구현을 제공합니다.
                            </p>
                            <a href="/e-digital" aria-label="바로가기" class="btn-go mt-5"></a>
                        </div>
                        <div class="col-lg-9 biz-area-card--img under-view">
                            <img src="@/assets/img/home-biz-3.png" alt="">
                        </div>
                    </div>
                </article>

                <article class="biz-area-card">
                    <div class="row">
                        <div class="col-lg-7 top-view">
                            <h3 class="biz-area-card--tit">EPIC Service</h3>
                            <p class="biz-area-card--txt">JUSTED의 Epic한 패러다임에 따라
                                세상의 가치를 바꿀 수 있는 저스티드 고유의
                                서비스 개발을 추진합니다.
                            </p>
                            <a href="/e-epicService" aria-label="바로가기" class="btn-go mt-5"></a>
                        </div>
                        <div class="col-lg-9 biz-area-card--img under-view">
                            <img src="@/assets/img/home-biz-4.png" alt="">
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>
