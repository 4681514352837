<template>
    <body class="epic-p">
        <header id="header" class="layout-header" :class="{'scroll-on':currentScroll}">
            <TopMenu />
        </header>
        <!-- sub banner -->
        <section class="container-fluid sub-banner epic-banner">
            <div class="sub-banner--title-wrap">
                <div class="sub-banner--sub-txt">EPIC Service</div>
                <div class="sub-banner--txt">
                    <span class="line">빠르게 변화하는 IT 세상 속,</span>
                    <span class="line">JUSTED와 EPIC Service만의</span>
                    <span class="line">새로운 가치가 새로운 내일을 만듭니다.</span>
                </div>
            </div>
        </section>

        <section class="section epic-business-section">
            <div class="container">
                <h2 class="tit-main">Business Model direction</h2>
                <!-- <p class="txt-sub work-process-txt">저스티드의 디지털 고객경험관리 방식</p> -->
                <div class="business-model-wrap">
                    <ul class="row">
                        <li class="col-lg-7">
                            <div class="epic-infos--img">
                                <img src="@/assets/img/epic-business01.jpg" alt="">
                                <div class="epic-infos">
                                    <h3 class="epic-infos-tit">AI-based Digital Content
                                        Platform</h3>
                                    <p class="epic-infos-txt">
                                        인공 지능(AI) 기술을 활용하여 다양한 산업분야에서 활용 가능한 
                                        디지털 콘텐츠를 제작하는 자동화 플랫폼을 실현합니다.
                                    </p>
                                </div>
                            </div>
                            
                        </li>
                        <li class="col-lg-7">
                            <div class="epic-infos--img">
                                <img src="@/assets/img/epic-business02.jpg" alt="">
                                <div class="epic-infos">
                                    <h3 class="epic-infos-tit">Eco-friendly Digital Asset 
                                        Management
                                        </h3>
                                    <p class="epic-infos-txt">
                                        분산된 시스템의 DAM을 개인화 콘텐츠에 걸맞은 디지털 에셋 관리를
                                        제공하며 더 나아가 에너지 자원 소비를 최소화한 친환경 에셋 관리를
                                        추구합니다.
                                    </p>
                                </div>
                            </div>
                            
                        </li>
                    </ul>
                </div>
            </div>
    </section>

    <section class="section epic-service-section">
        <div class="container">
            <h2 class="tit-main">Service</h2>
            <p class="txt-sub">EPIC한 패러다임의 세상의 가치를 바꿀 수 있는 고유한 EPIC Service Seed</p>
            <div class="service-wrap">
                <div class="service-item">
                    <div class="service-item--img">
                        <picture>
                            <source srcset="@/assets/img/epic-service_mo01.jpg" media="all and (max-width: 1024px)">
                            <img src="@/assets/img/epic-service_pc01.jpg" alt="">
                        </picture>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- bottom tel section -->

    <Connect />
    <Footer />

    </body>
    
</template>

<script setup>
import {ref, onMounted} from 'vue'
// 상단 Navbar 컴포넌트
import TopMenu from '../components/common-element/TopMenu.vue'

import Connect from '../components/common-element/BusinessConnect.vue'

// Footer
import Footer from '../components/common-element/Footer.vue'

// TopScroll
const currentScroll = ref("")

const topScrollEvent = () =>{
    if(window.scrollY > 100){
        currentScroll.value = "scroll-on"
    }else {
        currentScroll.value = ""
    }
}

onMounted(( )=>{
    window.addEventListener('scroll', topScrollEvent);
})

</script>

