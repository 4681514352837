<template>
    <body class="about-p">
        <header id="header" class="layout-header" :class="{'scroll-on':currentScroll}">
            <TopMenu />
        </header>

        <!-- sub banner -->
        <section class="container-fluid sub-banner about-banner">
            <div class="sub-banner--title-wrap">
                <div class="sub-banner--sub-txt">About</div>
                <div class="sub-banner--txt"><span class="line">JUSTED가 전하는 올바른 의지가 담긴</span>
                    <span class="line">EPIC paraDism</span></div>
                <div class="sub-banner--extra-txt">
                    우리는 스스로 ‘디지털 전문가’라고 부릅니다.<br class="br-mo">
                    JUSTED 이기에 가능한 *One-stop 서비스와 창조 정신으로<br class="br-sp">
                    모두의 IT Business에 신뢰, 소통, 나눔의 가치를 실현합니다.
                </div>
            </div>
        </section>
        <section class="section about-content-section">
            <div class="container">
                <div class="content-wrap">
                    <div class="content-item">
                        <span class="sub-txt">Value</span>
                        <h3 class="tit-main">JUST</h3>
                        <p class="txt-sub">알맞고 정확하게 올바른 길을 나아가는 의지</p>
                    </div>
                    <ul class="card-item">
                        <li>
                            <div class="card-item--wrap">
                                <div class="card-num">EPIC 01</div>
                                <div class="card-item--img">
                                    <img src="@/assets/img/about-img-01.png" alt="퍼즐 아이콘">
                                </div>
                                <div class="card-item--txt">
                                    <p class="card-main">알맞음</p>
                                    <p class="card-sub">
                                        우리는 어떤 문제도 알맞는 방식과 기술로<br class="br-sp">
                                        해결할 수 있어야 합니다.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="card-item--wrap">
                                <div class="card-num">EPIC 02</div>
                                <div class="card-item--img">
                                    <img src="@/assets/img/about-img-02.png" alt="퍼즐 아이콘">
                                </div>
                                <div class="card-item--txt">
                                    <p class="card-main">정확함</p>
                                    <p class="card-sub">
                                        우리는 언제나 올바른 기준으로<br class="br-sp">
                                        판단을 내려야 합니다.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="card-item--wrap">
                                <div class="card-num">EPIC 03</div>
                                <div class="card-item--img">
                                    <img src="@/assets/img/about-img-03.png" alt="퍼즐 아이콘">
                                </div>
                                <div class="card-item--txt">
                                    <p class="card-main">올바름</p>
                                    <p class="card-sub">
                                        우리는 언제나 올바른 생각과<br class="br-sp">
                                        판단을 해야 합니다.
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="content-wrap para-digm-wrap">
                    <div class="content-item">
                        <span class="sub-txt">Mission</span>
                        <h3 class="tit-main">EPIC paraDigm</h3>
                    </div>
                    <div class="para-digm-item">
                        <p class="para-tit">
                            빛나는 가치를<br class="br-mo"> 세상에 전하는 우리
                        </p>
                    </div>
                </div>
                <div class="content-wrap get-justed-wrap">
                    <div class="content-item">
                        <span class="sub-txt">Vision</span>
                        <h3 class="tit-main">Let's get &lt;&#47;JUSTED&gt;</h3>
                        <p class="txt-sub">
                            디지털 세상에 영향력 있는 가치를 전하고자 저스티드만의 올바르고 힘찬 의지를 나타냅니다.<br>
                            이 비전이 있기에 우리는 신뢰와 소통을 바탕으로 보다 의미있는 IT 서비스를 만들어 나아갑니다.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <!-- Cloud Transformation -->
        <section class="container-fluid section about-section">
            <div class="section-wrap">
                <div class="section-banner-title margin-b-0">
                    <h2 class="tit-main uiux-tit">저스티드와 함께 만드는 기술과 가치의 상호작용</h2>
                </div>
            </div>
        </section>
        <Footer />
    </body>
</template>

<script setup>
import {ref, onMounted } from 'vue'

// 상단 Navbar 컴포넌트
import TopMenu from '../components/common-element/TopMenu.vue'
// Footer
import Footer from '../components/common-element/Footer.vue'

// TopScroll
const currentScroll = ref("")

const topScrollEvent = () =>{
    if(window.scrollY > 100){
        currentScroll.value = "scroll-on"
    }else {
        currentScroll.value = ""
    }
}

onMounted(( )=>{
    window.addEventListener('scroll', topScrollEvent);
})

</script>

