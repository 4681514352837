<template>
    <div class="row layout-header--in align-content-center">
        <div class="col-8 col-lg-3">
            <a href="/" class="btn-home"></a>
        </div>
        <div class="col-8 col-lg-13 align-content-center">

            <button @click="openMoMenu" class="btn-hamburger" type="button" aria-label="모바일메뉴열기"></button>

            <div :class="{'active' : mobileMenu}" class="row gnb">
                <div class="col-lg-12">

                    <div class="mo-menu-top row justify-content-between">
                        <div class="col-8">
                            <a href="/" class="btn-home"></a>
                        </div>
                        <div class="col-8 ta-r">
                            <button @click="openMoMenu" type="button" aria-label="모바일메뉴닫기" class="btn-mo-close"></button>
                        </div>
                    </div>
                    
                    <h2 class="mo-tit">Business Area</h2>

                    <ul class="main-menu">
                        <li>
                            <a class="ebiz" href="/e-biz">e-Biz Service</a>
                        </li>
                        <li>
                            <a class="business" href="/e-solution">Business Solution</a>
                        </li>
                        <li>
                            <a class="digital" href="/e-digital">Digital CX·UIUX</a>
                        </li>
                        <li>
                            <a class="epic" href="/e-epicService">EPIC Service</a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-4 sub-menu">

                    <h2 class="mo-tit">Company</h2>

                    <ul>
                        <li>
                            <a class="about" href="/e-bizAbout">About</a>
                        </li>
                        <li>
                            <a class="locat" href="/e-location">Location</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import {ref , onMounted} from 'vue'

const mobileMenu = ref(false)

const openMoMenu = () =>{
    if (!mobileMenu.value) {
        mobileMenu.value = !mobileMenu.value
    }else {
        mobileMenu.value = !mobileMenu.value
    }
}
</script>