<template>
    <footer id="footer">
        <h2>
            <strong>(주)저스티드</strong> 
            <a href="/file/저스티드 회사소개서_V1.2.pdf" target="_blank" class="company-download-btn">
                <img src="@/assets/img/ico-download.svg" alt="">
                <span>회사소개서</span>
            </a>
        </h2>
    
        <address>
            <div class="address-main">Add. (06178) 서울특별시 강남구 테헤란로82길 15, 1454호</div>
            <div class="address-info">
                <span class="line">e-mail. support@justed.co.kr</span> <span class="mo-line"> | </span> <span class="line">tel. 02-2051-4877</span>
            </div>
        </address>
        <p>Copyright 2024 JUSTED Corp. All rights reserved.</p>
    </footer>

    <div class="float-area">
        <button @click="openQna(isOpen)" type="button" 
                aria-label="어떤 궁금증도 저스티드에게 물어봐주세요!"
                class="float-btn btn-qna">
        </button>
        <!-- Popup -->
        <div v-if="isOpen" 
            :class="addPopupClassName" 
            tabindex="0"
            >
            
            <h2 class="float-qna--tit">어떤 궁금증도
                저스티드에게 물어봐주세요!</h2>
            <ul class="float-qna--body">
                <li>
                    <a href="mailto: support@justed.co.kr" target="_blank">홈페이지를 만들고 싶어요!
                        또는 운영, 리뉴얼이 필요해요.</a>
                </li>
                <li>
                    <a href="https://www.ezencloud.co.kr" target="_blank">네트워크 마케팅 솔루션을
                        알아보고 있어요.</a>
                </li>
            </ul>
            <button @click="openQna(!isOpen)" 
                    type="button" 
                    class="float-qna--close" 
                    aria-label="닫기"></button>
        </div>
        <button
            @click="scrollToTop"
            type="button"
            aria-label="상단으로"
            class="float-btn btn-top"
            :class="{'show':showButton}">
        </button>

        
    </div>
</template>


<script setup>

import {ref , onMounted , onBeforeUnmount } from 'vue'

const showButton = ref(false);
let lastScrollTop = 0; // scroll height

// QNA Popup Y/N
const isOpen = ref(false)

// QNA Popup
const addPopupClassName = ref("")

// 상단위로
const upTopScroll = ref ("")

const openQna = () => {
    // float-qna appear
    // 현재 팝업이 닫혀있을경우.
    if (!isOpen.value) {
        isOpen.value = !isOpen.value
        addPopupClassName.value = "float-qna appear"
    }else {
        // 현재 팝업 닫기
        isOpen.value = !isOpen.value
        addPopupClassName.value = "float-qna"
    }
}

// 스크롤 핸들러 
const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > lastScrollTop) {
        // 스크롤 down
        showButton.value = true;
    } else {
        // 스크롤 up
        showButton.value = false;
    }
    // 모바일전용 스크롤
    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; 
};

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
});

</script>
