<template>
    <!--20240819 ebiz-p 클래스 추가-->
    <body class="ebiz-p">
        <header id="header" class="layout-header" :class="{'scroll-on':currentScroll}">
            <TopMenu/>
        </header>
        <!-- sub banner -->
        <section class="container-fluid sub-banner ebiz-banner">
            <div class="sub-banner--title-wrap">
                <div class="sub-banner--sub-txt">e-Biz Service</div>
                <div class="sub-banner--txt"><span class="line">빠르게 변화하는 IT 시대,</span>
                    <span class="line">디지털 비즈니스가 고민이신가요?</span></div>
                <div class="sub-banner--extra-txt">
                    Focus on Your work!<br>
                    업무 효율 증대와 빠른 비즈니스 성장을 위한<br>
                    최적의 E-BIZ 서비스를 제공합니다.
                </div>
            </div>
        </section>
        <section class="container work-process ebiz-work-section">
            <h2 class="tit-main">Work Process</h2>
            <p class="txt-sub work-process-txt">단 하나의 과정도 전문적인 노하우와 방법론을 통해 차원이 다른 서비스를 제공합니다.</p>
            <div class="work-process-step">
                <ul class="row">
                    <li class="col-8 col-lg-4 work-step">
                        <div class="work-step--top">
                            <div class="work-step--top-box arrow-r">
                                <span>step</span>
                                <strong>01</strong>
                            </div>
                        </div>
                        <div class="work-step--content bg01">
                            <div class="step-image">
                                <img src="@/assets/img/ico-ebiz-step1.svg" alt="">
                            </div>
                            <strong>요구사항 분석</strong>
                        </div>
                    </li>
                    <li class="col-8 col-lg-4 work-step">
                        <div class="work-step--top">
                            <div class="work-step--top-box arrow-r">
                                <span>step</span>
                                <strong>02</strong>
                            </div>
                        </div>
                        <div class="work-step--content bg02">
                            <div class="step-image">
                                <img src="@/assets/img/ico-ebiz-step2.svg" alt="">
                            </div>
                            <strong>전략 수집</strong>
                        </div>
                    </li>
                    <li class="col-8 col-lg-4 work-step">
                        <div class="work-step--top">
                            <div class="work-step--top-box arrow-r work-step-arrow">
                                <span>step</span>
                                <strong>03</strong>
                            </div>
                        </div>
                        <div class="work-step--content bg01">
                            <div class="step-image">
                                <img src="@/assets/img/ico-ebiz-step3.svg" alt="">
                            </div>
                            <strong>설계 및 개발</strong>
                        </div>
                    </li>
                    <li class="col-8 col-lg-4 work-step">
                        <div class="work-step--top">
                            <div class="work-step--top-box arrow-r work-step-arrow">
                                <span>step</span>
                                <strong>04</strong>
                            </div>
                        </div>
                        <div class="work-step--content bg02">
                            <div class="step-image">
                                <img src="@/assets/img/ico-ebiz-step4.svg" alt="">
                            </div>
                            <strong>모니터링 관리</strong>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <!-- service -->
        <section class="section ebiz-service-section">
            <div class="container">
                <div class="section-title">
                    <h2 class="section-title--txt">Service</h2>
                    <span class="section-title--sub-txt">디지털 비즈니스에 대한 심층 분석부터<br />
                        시스템 최적 서비스 운영까지 Total-Care<br class="mobile-br"> 서비스를 제공합니다.</span>
                </div>

                <ul class="row">
                    <li class="col-lg-4">
                        <div class="service-infos--img">
                            <img src="@/assets/img/ebiz-service01.svg" alt="">
                        </div>
                        <div class="service-infos--content">
                            <h3 class="tit-sub service-infos-tit">비즈니스 통합 운영서비스</h3>
                            <p class="service-infos-txt">모바일 앱, 반응형 웹사이트,
                                모바일 웹사이트 등 다양한 디지털
                                비즈니스 서비스에 대해 통합적인
                                유지보수, 운영서비스를 제공합니다.  </p>
                        </div>
                    </li>
                    <li class="col-lg-4">
                        <div class="service-infos--img">
                            <img src="@/assets/img/ebiz-service02.png" alt="">
                        </div>
                        <div class="service-infos--content">
                            <h3 class="tit-sub service-infos-tit">비즈니스 플랫폼 컨설팅</h3>
                            <p class="service-infos-txt">디지털 비즈니스의 시작, 데이터 기반의 
                                효율적인 운영을 위한 종합적인 관점의 
                                비즈니스 플랫폼 컨설팅을 제공하여 
                                비즈니스의 핵심 가치를 만들어드립니다.</p>
                        </div>
                    </li>
                    <li class="col-lg-4">
                        <div class="service-infos--img">
                            <img src="@/assets/img/ebiz-service03.svg" alt="">
                        </div>
                        <div class="service-infos--content">
                            <h3 class="tit-sub service-infos-tit">WEB & Mobile APP 제작</h3>
                            <p class="service-infos-txt">저스티드는 모바일 앱, 반응형 웹
                                사이트, 모바일 웹사이트 등 다양한 제작 
                                경험을 기반으로 최적의 e-Biz 서비스 
                                채널을 구축합니다.</p>
                        </div>
                    </li>
                    <li class="col-lg-4">
                        <div class="service-infos--img">
                            <img src="@/assets/img/ebiz-service04.svg" alt="">
                        </div>
                        <div class="service-infos--content">
                            <h3 class="tit-sub service-infos-tit">Cloud Transformation</h3>
                            <p class="service-infos-txt">고객 만족과 서비스 품질 혁신을 위한 
                                클라우드 컨설팅, 빅데이터, 마이그레이션 
                                등 최적의 클라우드 환경 구축 서비스를 
                                제공합니다.</p>
                        </div>
                    </li>
                </ul>
            </div>
    </section>
    <!-- Just Effect -->
    <section class="container section ebiz-just-section">
        <div class="section-title margin-b-0">
            <h2 class="section-title--txt">JUST-Effect</h2>
        </div>

        <div class="row ebiz-just-section--content">
            <div class="col-lg-10 ebiz-just-section--content-img">
                <img src="@/assets/img/ebiz-just-effect.png" alt="">
            </div>
            <div class="col-lg-6 ebiz-just-section--content-des">
                <dl>
                    <dt>비즈니스 통찰력 향상</dt>
                    <dd>실시간 정보 반영 및 일관성 있는 인프라 제공</dd>
                </dl>
                <dl>
                    <dt>효율적 운영&비용 절감</dt>
                    <dd>간소화된 비즈니스 프로세스 및 모범 사례 제공<br />
                        효율적인 클라우드 기반 운영 통합 관리 자동화로 효율성 극대화</dd>
                </dl>
                <dl>
                    <dt>체계적 협업</dt>
                    <dd>JIRA, Redmine 등 전문 툴을 사용한<br class="ebiz-mo-br"> 체계적인 협업과<br class="ebiz-pc-br">
                        데이터 공유 및 유연한<br class="ebiz-mo-br"> 의사결정을 위한 협업 프로세스 제공</dd>
                </dl>
                <dl>
                    <dt>유연한 확장</dt>
                    <dd>국내 외 기업이 애용하는 클라우드 서비스를 통한 유연한 확장<br />
                        Focus on your work!</dd>
                </dl>
            </div>
        </div>
    </section>
    <!-- Cloud Transformation -->
    <section class="container-fluid section ebiz-cloud-section">
        <div class="section-wrap">
            <div class="section-banner-title margin-b-0">
                <h2 class="tit-mid section-banner-title--txt">Cloud Transformation Work Process</h2>
                <div class="row justify-content-between ebiz-cloud-step">
                    <div class="col-8 col-lg-3 order-1">
                        <div class="ebiz-cloud-unit">
                            <div class="ebiz-cloud-unit-in">
                                <img src="@/assets/img/ebiz-cloud-ico01.svg" alt="">
                                <p class="ebiz-cloud-unit-txt">환경분석</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 col-lg-3 order-2">
                        <div class="ebiz-cloud-unit">
                            <div class="ebiz-cloud-unit-in">
                                <img src="@/assets/img/ebiz-cloud-ico02.svg" alt="">
                                <p class="ebiz-cloud-unit-txt">클라우드 모델링</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 col-lg-3 order-4 order-lg-3">
                        <div class="ebiz-cloud-unit">
                            <div class="ebiz-cloud-unit-in">
                                <img src="@/assets/img/ebiz-cloud-ico03.svg" alt="">
                                <p class="ebiz-cloud-unit-txt">클라우드 이전</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 col-lg-3 order-3 order-lg-4">
                        <div class="ebiz-cloud-unit">
                            <div class="ebiz-cloud-unit-in">
                                <img src="@/assets/img/ebiz-cloud-ico04.svg" alt="">
                                <p class="ebiz-cloud-unit-txt">구축&개발</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 col-lg-3 order-5">
                        <div class="ebiz-cloud-unit ebiz-cloud-unit-last">
                            <div class="ebiz-cloud-unit-in">
                                <img src="@/assets/img/ebiz-cloud-ico05.svg" alt="">
                                <p class="ebiz-cloud-unit-txt">모니터링 관리</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- 연락처 -->
    <Connect />

    <Footer></Footer>
    </body>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import TopMenu from '../components/common-element/TopMenu.vue'
import Connect from '../components/common-element/BusinessConnect.vue'
import Footer from '../components/common-element/Footer.vue'

// TopScroll
const currentScroll = ref("")

const topScrollEvent = () =>{
    if(window.scrollY > 100){
        currentScroll.value = "scroll-on"
    }else {
        currentScroll.value = ""
    }
}

onMounted(( )=>{
    window.addEventListener('scroll', topScrollEvent);
})
</script>

