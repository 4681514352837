<template>
    <body class="digi-p">
        <header id="header" class="layout-header" :class="{'scroll-on':currentScroll}">
            <TopMenu />
        </header>
        <!-- sub banner -->
        <section class="container-fluid sub-banner ebiz-banner digital-banner">
            <div class="sub-banner--title-wrap">
                <div class="sub-banner--sub-txt">Digital CX·UIUX</div>
                <div class="sub-banner--txt">
                    <span class="line">Customer Experience 중심</span>
                    <span class="line">고객과 연결 되는 새로운 방식의 UIUX 구현</span>
                </div>
            </div>
        </section>

        <section class="section-my work-process">
            <div class="container">
                <h2 class="tit-main">Work Process</h2>
                <p class="txt-sub work-process-txt">저스티드의 디지털 고객경험관리 방식</p>
            </div>
            <div class="p-r">
                <div class="container digital-tab-btn">
                    <div class="digital-tab-scroll">
                        
                        <button 
                                @click="swiperMove(0)" 
                                type="button" 
                                class="tab-button-blue  digital-tab ml-5"
                                :class="{'active' : this.activeTapIndex === 0}">
                                Strategy
                        </button>

                        <button @click="swiperMove(1)"
                                type="button" 
                                class="tab-button-blue digital-tab ml-5"
                                :class="{'active' : this.activeTapIndex === 1}">
                                Plan
                        </button>

                        <button @click="swiperMove(2)" 
                                type="button" 
                                class="tab-button-blue digital-tab ml-5"
                                :class="{'active' : this.activeTapIndex === 2}"
                                >
                                Design
                        </button>
                    </div>
                </div>

                <!-- Slider main container -->
                <div id="swiper" class="swiper">
                    <!-- Additional required wrapper -->
                    <div class="swiper-wrapper">
                        <!-- Slides -->
                        <div class="swiper-slide">
                            <div class="container">
                                <div class="label digital-slide-txt digital-slide-label">STEP 01</div>
                                <div class="row">
                                    <div class="col-lg-10 order-2 order-lg-1">
                                        <div class="digital-slide-txt">
                                            <h2 class="digital-slide-tit digital-slide-txt2 mb-5">Define Digital Strategy</h2>
                                            <p class="digital-slide-desc">사업 전략 수립만을 위한 디지털 경험 분석이 아닌
                                                전사의 모든 디지털 서비스 현황과 디지털 고객 경험을
                                                분석하여 <strong>최상의 맞춤 전략을 도출</strong>합니다.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 digital-slide-desc-img">
                                        <img src="@/assets/img/digital_slide01.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide swiper-slide02">
                            <div class="container">
                                <div class="label digital-slide-txt digital-slide-label">STEP 02</div>
                                <div class="row">
                                    <div class="col-lg-10 order-2 order-lg-1">
                                        <div class="digital-slide-txt">
                                            <h2 class="digital-slide-tit digital-slide-txt2 mb-5">Build Over-all Plan</h2>
                                            <p class="digital-slide-desc">개발 환경 선정부터 서비스 운영 방안, <br>
                                                시스템 도입까지 모두 고려한
                                                분석하여 <strong>디지털 채널 구현</strong>의 <strong>종합적 플랜을 제공</strong>합니다.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 digital-slide-desc-img">
                                        <img src="@/assets/img/digital_slide02.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide swiper-slide03">
                            <div class="container">
                                <div class="label digital-slide-txt digital-slide-label">STEP 03</div>
                                <div class="row">
                                    <div class="col-lg-10 order-2 order-lg-1">
                                        <div class="digital-slide-txt">
                                            <h2 class="digital-slide-tit digital-slide-txt2 mb-5">Build Over-all Plan</h2>
                                            <p class="digital-slide-desc">개발 환경 선정부터 서비스 운영 방안, <br>
                                                시스템 도입까지 모두 고려한
                                                분석하여 <strong>디지털 채널 구현</strong>의 <strong>종합적 플랜을 제공</strong>합니다.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 digital-slide-desc-img">
                                        <img src="@/assets/img/digital_slide03.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- If we need pagination -->
                </div>
                <div class="swiper-pagination">Test</div>
            </div>
        </section>

        <!-- Cloud Transformation -->
        <section class="container-fluid section ebiz-cloud-section">
            <div class="section-wrap">
                <div class="section-banner-title margin-b-0">
                    <div class="uiux-sub">Digital Consumer eXperience</div>
                    <h2 class="tit-main uiux-tit">Best UIUX Design for DCX Optimization</h2>
                    <p class="txt-sub c-white">고도화된 AI 개인화 시대,<br class="ebiz-mo-br"> 모든 사용자를 위한 더 나은 UX/UI 설계<br class="ebiz-mo-br"><br class="ebiz-pc-br">
                        DCX는 사업부는 사용자 경험의 미래를<br class="ebiz-mo-br"> 디자인합니다.</p>
                </div>
            </div>
        </section>

        <section class="container-fluid section ebiz-customer-section">
            <div class="container">
                <div class="section-wrap">
                    <div class="customer-img">
                        <img src="@/assets/img/digital_customer.png" alt="CX,UX,CI 이미지">
                    </div>
                    <div class="text">
                        <h3 class="customer-tit">Digital Customer Experience 란</h3>
                        <p class="customer-txt">디지털 고객 경험(DCX)은 기업과 접하는 모든 고객 여정, 행동, 감정 요인을 포함합니다.<br>
                            비대면 서비스가 확대되면서 디지털 고객 경험은 제조, 유통, 금융을 포함한 모든 사업 분야에서 성공 필수 요인이 되었습니다.<br>
                            고객이 기업과 거래, 소통하는 모든 단계에서 긍정적 경험을 지속할 수 있도록 총체적 경험 관리를 통합적으로 분석하여 서비스를 설계하고 디자인합니다</p>
                    </div>
                </div>
            </div>
        </section>

        <!-- bottom tel section -->
        <section class="section ebiz-tel-section">
            <div class="container">
                <div class="row align-content-center">
                    <div class="col-lg-10 tel-area-txt">
                        <p class="ebiz-tel-section-txt">디지털 비즈니스 성공을 위한<br class="mo-br"> 가장 빠른 방법, 작은 고민도 저스티드와 나누고 소통해보세요!</p>
                    </div>
                    <div class="col-lg-6 tel-area-btn">
                        <button onclick="self.location.href = 'tel:02-2051-4877'" type="button" class="btn-line btn-line--call"><span>02-2051-4877</span></button> 
                        <button onclick="self.location.href = 'mailto:support@justed.co.kr'" type="button" class="btn-line btn-line--email ml-5">support@justed.co.kr</button>
                    </div>
                </div>
            </div>
        </section>
        <!-- Footer -->
    <Footer />
</body>
</template>

<script >
import {ref, onMounted } from 'vue'

import TopMenu from '../components/common-element/TopMenu.vue'
import Footer from '../components/common-element/Footer.vue'

import Swiper, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

  export default {

    components :{
        TopMenu,
        Footer
    },
    data() {
      return {
        swiper: null,
        activeTapIndex : 0,
        currentScroll : ""
      };
    },
    methods : {
        
    },
    mounted() {
        this.swiper = new Swiper('#swiper', {

        modules: [Pagination],
        centeredSlides: true,
        slidesPerView: 1.33,
        spaceBetween: 58,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: (index, className) => {
                return `
                    <span class="${className}">
                    0${index + 1}
                    <span class='swiper-pagination-edge'></span>
                    </span>`;
            }
      },
      breakpoints: {
        720: {
          slidesPerView: 1.33,
          spaceBetween: 40
        },
        350: {
          slidesPerView: 1.05,
          spaceBetween: 30
        }
      },
      on: {
        slideChange: () => {
          this.activeTapIndex = this.swiper.realIndex;
        }
      }
    });
    window.addEventListener('scroll', this.topScrollEvent);
  },
  methods: {
    swiperMove(num) {
      this.swiper.slideTo(num);
    },

    topScrollEvent () {
        if(window.scrollY > 100){
            this.currentScroll = "scroll-on"
        }else {
            this.currentScroll = ""
        }
    }
  }
};
</script>
