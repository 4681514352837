<template>
    <section class="home-visual">
        <header id="header" class="layout-header" :class="{'scroll-on':currentScroll}">
            <!-- 상단 Navbar -->
            <TopMenu/>
        </header>

        <div class="home-visual--txt-wrap">
            <div class="home-visual--sub-txt">기술과 가치의 상호작용</div>
            <div class="home-visual--txt">What's JUST Epic paraDigm?</div>
        </div>
        <div class="home-visual--arrow">
            <img src="@/assets/img/home-ico_down.svg" alt="">
        </div>

        <div class="home-visual--movie-wrap">
            <video src="@/assets/img/main_visual2.mp4" autoplay muted loop class="home-visual--movie"></video>
            <div class="dim"></div>
        </div>
    </section>

    <section class="home-txt-area--wrap">
        <div class="home-txt-area">
            <!-- Step 1 -->
            <div class="home-txt-area--main tx1">저스티드는</div>
            <!-- Step 2 -->
            <div class="home-txt-area--main tx2">진짜 문제를 해결할 수 있는</div>
            <!-- Step 3 -->
            <div class="home-txt-area--main tx3">'구조적 고민'을 합니다.</div>
            <!-- Step 4 -->
            <div class="home-txt-area--sub">We Make JUST-IT</div>
            <!-- Step 5 -->
            <div class="home-txt-area--sub2">의미있는 고민의 결과,<br class="main-br"> Ease to Epic Use를 통해<br class="main-br"> 세상의 가치가 변화합니다</div>
        </div>
    </section>

    <!-- 스크롤 아래 이동 후 사업영역 컴포넌트-->
    <BusinessAreaInfo />

    <!-- 사업영역 다음으로 Work Scop영역 컴포넌트-->
    <WorkScop />

    <!-- Footer -->
    <Footer />

</template>

<script setup>

import {ref, onMounted,onBeforeUnmount} from 'vue'

// 상단 Navbar 컴포넌트
import TopMenu from '../components/common-element/TopMenu.vue'
// 중간 사업영역표시 컴포넌트
import BusinessAreaInfo from '../components/common-element/BusinessAreaInfo.vue'
// 하단 Work Scop 컴포넌트
import WorkScop from '../components/common-element/WorkScop.vue'
// Footer
import Footer from '../components/common-element/Footer.vue'

// gsap > scroll animation
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

// TopScroll
const currentScroll = ref("")

const topScrollEvent = () =>{
    if(window.scrollY > 100){
        currentScroll.value = "scroll-on"
    }else {
        currentScroll.value = ""
    }
}
onMounted(() => {
    gsap.registerPlugin(ScrollTrigger);

    // 모바일 width 체크
    const isMobile425 = window.matchMedia("(max-width: 425px)").matches; // 화면 너비가 425px 이하인지 확인합니다.
    const isMobile768 = window.matchMedia("(max-width: 768px)").matches; // 화면 너비가 768px 이하인지 확인합니다.
    
    // 모바일 & PC공통설정
    let sec1 = gsap.timeline({
        scrollTrigger : {
            trigger : ".home-txt-area--wrap", // 트리거 요소 설정
            pin : true , // 스크롤 중 요소고정
            start : "top top", // 애니메이션 시작
            end : "+=400%",    // 애니메이션 종료
            scrub: 0.5,        // 스크롤 움직임에따라 진행동기화

        }
    })
    sec1.from(".home-txt-area .tx1", { opacity: 0, transform: "scale(8)", duration: 0.4, ease: "none" });
    sec1.to(".home-txt-area .tx1", { opacity: 1, transform: "scale(1)", duration: 0.2, ease: "none" });
    sec1.from(".home-txt-area .tx2", { opacity: 0, transform: "scale(8)", duration: 0.4, ease: "none" });
    sec1.to(".home-txt-area .tx2", { opacity: 1, transform: "scale(1)", duration: 0.2, ease: "none" });
    sec1.from(".home-txt-area .tx3", { opacity: 0, transform: "scale(8)", duration: 0.4, ease: "none" });
    sec1.to(".home-txt-area .tx3", { opacity: 1, transform: "scale(1)", duration: 0.2, ease: "none" });
    sec1.from(".home-txt-area .home-txt-area--sub", { opacity: 0, transform: "translateY(20px)", duration: 0.4, ease: "none" });
    sec1.to(".home-txt-area .home-txt-area--sub", { opacity: 1, transform: "translateY(0)", duration: 0.2, ease: "none" });
    sec1.from(".home-txt-area .home-txt-area--sub2", { opacity: 0, transform: "translateY(20px)", duration: 0.4, ease: "none" });
    sec1.to(".home-txt-area .home-txt-area--sub2", { opacity: 1, transform: "translateY(0)", duration: 0.2, ease: "none" });

    if (isMobile425) {
        // 425px 이하 설정
        let sec2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".biz-area--wrap.st02", // 객체기준범위
                start: "10% top", // 시작점
                end: "bottom top", // 끝점 조정
                once: true, // 한 번만 작동하도록 설정
                // markers: true
            }
        });

        sec2.from(".work-area--circle .work-area--unit--1", {
            transform: 'translateX(-150%)',
            duration: 0.2,
            ease: "none"
        });
        sec2.to(".work-area--circle .work-area--unit--1", {
            transform: 'translateX(-125%)',
            duration: 0.2,
            ease: "none"
        });
        sec2.from(".work-area--circle .work-area--unit--3", {
            transform: 'translateX(50%)',
            duration: 0.2,
            ease: "none"
        });
        sec2.to(".work-area--circle .work-area--unit--3", {
            transform: 'translateX(25%)',
            duration: 0.2,
            ease: "none"
        });

        let sec3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".biz-area--wrap.st02", // 객체기준범위
                start: "10% top", // 시작점
                end: "bottom top", // 끝점 조정
                once: true, // 한 번만 작동하도록 설정
                // markers: true
            }
        });

        sec3.from(".work-area--txt", {
            opacity: 0,
            transform: "translateY(20px)",
            duration: 0.4,
            ease: "none"
        });
        sec3.to(".work-area--txt", {
            opacity: 1,
            transform: "translateY(0)",
            duration: 0.2,
            ease: "none"
        });
        } else if (isMobile768) {
            // 768px 이하 설정
            let sec2 = gsap.timeline({
                scrollTrigger: {
                    trigger: ".biz-area--wrap.st02", // 객체기준범위
                    start: "10% top", // 시작점
                    end: "bottom top", // 끝점 조정
                    once: true, // 한 번만 작동하도록 설정
                    // markers: true
                }
            });

            sec2.from(".work-area--circle .work-area--unit--1", {
                transform: 'translateX(0%)',
                duration: 0.2,
                ease: "none"
            });
            sec2.to(".work-area--circle .work-area--unit--1", {
                transform: 'translateX(95%)',
                duration: 0.2,
                ease: "none"
            });
            sec2.from(".work-area--circle .work-area--unit--3", {
                transform: 'translateX(0%)',
                duration: 0.2,
                ease: "none"
            });
            sec2.to(".work-area--circle .work-area--unit--3", {
                transform: 'translateX(-95%)',
                duration: 0.2,
                ease: "none"
            });

            let sec3 = gsap.timeline({
                scrollTrigger: {
                    trigger: ".biz-area--wrap.st02", // 객체기준범위
                    start: "10% top", // 시작점
                    end: "bottom top", // 끝점 조정
                    once: true, // 한 번만 작동하도록 설정
                    // markers: true
                }
            });

            sec3.from(".work-area--txt", {
                opacity: 0,
                transform: "translateY(20px)",
                duration: 0.4,
                ease: "none"
            });
            sec3.to(".work-area--txt", {
                opacity: 1,
                transform: "translateY(0)",
                duration: 0.2,
                ease: "none"
            });
            }else {
                // PC용 설정 (pin 사용 안함)
                let sec2 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".biz-area--wrap.st02", // 객체기준범위
                        start: "10% top", // 시작점
                        end: "bottom top", // 끝점 조정
                        once: true, // 한 번만 작동하도록 설정
                        // markers: true
                    }
                });
                sec2.from(".work-area--circle .work-area--unit--1", { transform: 'translateX(0%)', duration: 0.2, ease: "none" });
                sec2.to(".work-area--circle .work-area--unit--1", { transform: 'translateX(50%)', duration: 0.2, ease: "none" });
                sec2.from(".work-area--circle .work-area--unit--3", { transform: 'translateX(0%)', duration: 0.2, ease: "none" });
                sec2.to(".work-area--circle .work-area--unit--3", { transform: 'translateX(-50%)', duration: 0.2, ease: "none" });

                let sec3 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".biz-area--wrap.st02", // 객체기준범위
                        start: "40% top", // 시작점
                        end: "bottom top", // 끝점 조정
                        once: true, // 한 번만 작동하도록 설정
                        // markers: true
                    }
                });
                sec3.from(".work-area--txt", { opacity: 0, transform: "translateY(20px)", duration: 0.4, ease: "none" });
                sec3.to(".work-area--txt", { opacity: 1, transform: "translateY(0)", duration: 0.2, ease: "none" });
            }
        window.addEventListener('scroll', topScrollEvent);
})

</script>