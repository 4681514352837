<template>
    <section class="container biz-area--wrap st02">
        <div class="row work-area--tit">
            <div class="col-16">
                <div class="tit-line">
                    <span>Work Scop</span>
                </div>
                <h2 class="tit-main mt-3 mt-lg-5">Strong Point</h2>
                <ul class="txt-sub mt-4">
                    <li>어떤 분야도 저스티드 전문가가 제공하는  
                        맞춤 개발&비즈니스 성공 달성</li>
                </ul>
            </div>
        </div>

        <div class="work-area--circle">
            <div class="work-area--unit work-area--unit--1">
                <span class="work-area--unit--txt">효율적인 <br>
                    시스템 구축</span>
            </div>
            <div class="work-area--unit work-area--unit--2">
                <span class="work-area--unit--txt">
                    안정적인 <br>
                    시스템 운영
                </span>
            </div>
            <div class="work-area--unit work-area--unit--3">
                <span class="work-area--unit--txt">
                    맞춤형 비즈니스 <br>
                    서비스
                </span>
            </div>
        </div>
        <div class="ta-c work-area--txt">맞춤 개발&비즈니스 성공 달성</div>
    </section>

    <section class="contact-area">
        <div class="container">
            <span class="contact-area--txt">Contact Us</span>
            <p class="contact-area--tit">디지털 비즈니스 <br>
                성공을 위한 <br>
                가장 빠른 수단</p>
            <p class="contact-area--ment">작은 고민도 저스티드와 나누고 소통해보세요!</p>
            <button onclick="self.location.href = 'tel:02-2051-4877'" type="button" class="btn-line btn-line--call"><span>02-2051-4877</span></button>
            <button onclick="self.location.href = 'mailto:support@justed.co.kr'" type="button" class="btn-line btn-line--email ml-5">support@justed.co.kr</button>
        </div>
    </section>

</template>
