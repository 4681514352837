<template>
    <body class="locat-p">
        <header id="header" class="layout-header" :class="{'scroll-on':currentScroll}">
        <TopMenu />
    </header>
    <!-- sub banner -->
    <section class="container-fluid sub-banner location-banner">
        <div class="sub-banner--title-wrap">
            <div class="sub-banner--sub-txt">Location</div>
            <div class="sub-banner--txt"><span class="line">저스티드는 고객의 가치를</span>
                <span class="line">최우선으로 생각합니다.</span></div>
        </div>
    </section>

    <section class="section location-section">
        <div class="container">
            <h2 class="tit-main">오시는 길</h2>
            <div class="map-wrap">
                <div id="daumRoughmapContainer1721347254521" class="root_daum_roughmap root_daum_roughmap_landing"></div>
            </div>
            <hr class="hr-line">
            <ul class="location-info">
                <li class="item">
                    <p class="tit">ADD</p>
                    <p class="txt">(06178) 서울특별시 강남구<br>
                        테헤란로82길 15, 디아이타워 1454호</p>
                </li>
                <li class="item">
                    <p class="tit">TEL</p>
                    <p class="txt">02-2051-4877</p>
                </li>

                <li class="item">
                    <p class="tit">E-MAIL</p>
                    <p class="txt">support@justed.co.kr</p>
                </li>
                <li class="item">
                    <p class="tit">교통편</p>
                    <ul class="location-info-list">
                        <li>
                            <span class="sub-tit">전철</span>
                            <span class="sub-txt">삼성역 4번 출구 도보 10분</span>
                        </li>
                        <li>
                            <span class="sub-tit">버스</span>
                            <span class="sub-txt">2416, 4425, 강남07<br> 
                                포스코사거리 하차</span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </section>

    <!-- bottom tel section -->
    <section class="section ebiz-tel-section">
        <div class="container">
            <div class="row align-content-center">
                <div class="col-lg-10 tel-area-txt">
                    <p class="ebiz-tel-section-txt">디지털 비즈니스 성공을 위한<br class="mo-br"> 가장 빠른 방법, 작은 고민도 저스티드와 나누고 소통해보세요!</p>
                </div>
                <div class="col-lg-6 tel-area-btn">
                    <button onclick="self.location.href = 'tel:02-2051-4877'" type="button" class="btn-line btn-line--call"><span>02-2051-4877</span></button> 
                    <button onclick="self.location.href = 'mailto:support@justed.co.kr'" type="button" class="btn-line btn-line--email">support@justed.co.kr</button>
                </div>
            </div>
        </div>
    </section>
    <Footer />

    </body>
    
</template>

<script setup>
import {ref, onMounted } from 'vue'
// 상단 Navbar 컴포넌트
import TopMenu from '../components/common-element/TopMenu.vue'
// Footer
import Footer from '../components/common-element/Footer.vue'

const currentScroll = ref("")
const topScrollEvent = () =>{
    if(window.scrollY > 100){
        currentScroll.value = "scroll-on"
    }else {
        currentScroll.value = ""
    }
}

onMounted(() =>{
    new daum.roughmap.Lander({
        "timestamp" : "1721347254521",
        "key" : "2k48e",
        "mapWidth" : "100%",
        "mapHeight" : "400"
    }).render();
    window.addEventListener('scroll', topScrollEvent);
})
</script>